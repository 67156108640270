export const countryList = [
  {
    code: "SA",
    name: "Saudi Arabia",
        countryCode:'KSA'
  },
  {
    code: "AE",
    name: "United Arab Emirates",
        countryCode:'UAE'
  },
  {
    code: "AQ",
    name: "Rest of Gulf",
        countryCode:'ROG'
  },
];

export const countryCode = [
  { countryCodes: ["+93"], country: "AF" },
  { countryCodes: ["+355"], country: "AL" },
  { countryCodes: ["+213"], country: "DZ" },
  { countryCodes: ["+1-684"], country: "AS" },
  { countryCodes: ["+376"], country: "AD" },
  { countryCodes: ["+244"], country: "AO" },
  { countryCodes: ["+1-264"], country: "AI" },
  { countryCodes: ["+672"], country: "AQ" },
  { countryCodes: ["+54"], country: "AR" },
  { countryCodes: ["+374"], country: "AM" },
  { countryCodes: ["+297"], country: "AW" },
  { countryCodes: ["+61"], country: "AU" },
  { countryCodes: ["+43"], country: "AT" },
  { countryCodes: ["+994"], country: "AZ" },
  { countryCodes: ["+1-242"], country: "BS" },
  { countryCodes: ["+973"], country: "BH" },
  { countryCodes: ["+880"], country: "BD" },
  { countryCodes: ["+1-246"], country: "BB" },
  { countryCodes: ["+375"], country: "BY" },
  { countryCodes: ["+32"], country: "BE" },
  { countryCodes: ["+501"], country: "BZ" },
  { countryCodes: ["+229"], country: "BJ" },
  { countryCodes: ["+1-441"], country: "BM" },
  { countryCodes: ["+975"], country: "BT" },
  { countryCodes: ["+591"], country: "BO" },
  { countryCodes: ["+387"], country: "BA" },
  { countryCodes: ["+267"], country: "BW" },
  { countryCodes: ["+55"], country: "BR" },
  { countryCodes: ["+246"], country: "IO" },
  { countryCodes: ["+359"], country: "BG" },
  { countryCodes: ["+226"], country: "BF" },
  { countryCodes: ["+257"], country: "BI" },
  { countryCodes: ["+855"], country: "KH" },
  { countryCodes: ["+237"], country: "CM" },
  { countryCodes: ["+1"], country: "CA" },
  { countryCodes: ["+238"], country: "CV" },
  { countryCodes: ["+1-345"], country: "KY" },
  { countryCodes: ["+236"], country: "CF" },
  { countryCodes: ["+235"], country: "TD" },
  { countryCodes: ["+56"], country: "CL" },
  // { countryCodes: ["+86"], country: "CN" },
  // { countryCodes: ["+61"], country: "CX" },
  { countryCodes: ["+61"], country: "CC" },
  { countryCodes: ["+57"], country: "CO" },
  { countryCodes: ["+269"], country: "KM" },
  { countryCodes: ["+242"], country: "CG" },
  { countryCodes: ["+682"], country: "CK" },
  { countryCodes: ["+506"], country: "CR" },
  { countryCodes: ["+225"], country: "CI" },
  { countryCodes: ["+385"], country: "HR" },
  { countryCodes: ["+53"], country: "CU" },
  { countryCodes: ["+357"], country: "CY" },
  { countryCodes: ["+420"], country: "CZ" },
  { countryCodes: ["+45"], country: "DK" },
  { countryCodes: ["+253"], country: "DJ" },
  { countryCodes: ["+1-767"], country: "DM" },
  { countryCodes: ["+1-809"], country: "DO" },
  { countryCodes: ["+1-829"], country: "DO" },
  { countryCodes: ["+1-849"], country: "DO" },
  { countryCodes: ["+593"], country: "EC" },
  { countryCodes: ["+20"], country: "EG" },
  { countryCodes: ["+503"], country: "SV" },
  { countryCodes: ["+240"], country: "GQ" },
  { countryCodes: ["+291"], country: "ER" },
  { countryCodes: ["+372"], country: "EE" },
  { countryCodes: ["+251"], country: "ET" },
  { countryCodes: ["+500"], country: "FK" },
  { countryCodes: ["+298"], country: "FO" },
  { countryCodes: ["+679"], country: "FJ" },
  { countryCodes: ["+358"], country: "FI" },
  { countryCodes: ["+33"], country: "FR" },
  { countryCodes: ["+594"], country: "GF" },
  { countryCodes: ["+689"], country: "PF" },
  { countryCodes: ["+241"], country: "GA" },
  { countryCodes: ["+220"], country: "GM" },
  { countryCodes: ["+995"], country: "GE" },
  { countryCodes: ["+49"], country: "DE" },
  { countryCodes: ["+233"], country: "GH" },
  { countryCodes: ["+350"], country: "GI" },
  { countryCodes: ["+30"], country: "GR" },
  { countryCodes: ["+299"], country: "GL" },
  { countryCodes: ["+1-473"], country: "GD" },
  { countryCodes: ["+590"], country: "GP" },
  { countryCodes: ["+1-671"], country: "GU" },
  { countryCodes: ["+502"], country: "GT" },
  { countryCodes: ["+224"], country: "GN" },
  { countryCodes: ["+245"], country: "GW" },
  { countryCodes: ["+592"], country: "GY" },
  { countryCodes: ["+509"], country: "HT" },
  { countryCodes: ["+504"], country: "HN" },
  { countryCodes: ["+852"], country: "HK" },
  { countryCodes: ["+36"], country: "HU" },
  { countryCodes: ["+354"], country: "IS" },
  { countryCodes: ["+91"], country: "IN" },
  { countryCodes: ["+62"], country: "ID" },
  { countryCodes: ["+98"], country: "IR" },
  { countryCodes: ["+964"], country: "IQ" },
  { countryCodes: ["+353"], country: "IE" },
  { countryCodes: ["+972"], country: "IL" },
  { countryCodes: ["+39"], country: "IT" },
  { countryCodes: ["+1-876"], country: "JM" },
  { countryCodes: ["+81"], country: "JP" },
  { countryCodes: ["+962"], country: "JO" },
  { countryCodes: ["+7"], country: "KZ" },
  { countryCodes: ["+254"], country: "KE" },
  { countryCodes: ["+686"], country: "KI" },
  { countryCodes: ["+383"], country: "XK" },
  { countryCodes: ["+965"], country: "KW" },
  { countryCodes: ["+996"], country: "KG" },
  { countryCodes: ["+856"], country: "LA" },
  { countryCodes: ["+371"], country: "LV" },
  { countryCodes: ["+961"], country: "LB" },
  { countryCodes: ["+266"], country: "LS" },
  { countryCodes: ["+231"], country: "LR" },
  { countryCodes: ["+218"], country: "LY" },
  { countryCodes: ["+423"], country: "LI" },
  { countryCodes: ["+370"], country: "LT" },
  { countryCodes: ["+352"], country: "LU" },
  { countryCodes: ["+853"], country: "MO" },
  { countryCodes: ["+389"], country: "MK" },
  { countryCodes: ["+261"], country: "MG" },
  { countryCodes: ["+265"], country: "MW" },
  { countryCodes: ["+60"], country: "MY" },
  { countryCodes: ["+960"], country: "MV" },
  { countryCodes: ["+223"], country: "ML" },
  { countryCodes: ["+356"], country: "MT" },
  { countryCodes: ["+692"], country: "MH" },
  { countryCodes: ["+596"], country: "MQ" },
  { countryCodes: ["+222"], country: "MR" },
  { countryCodes: ["+230"], country: "MU" },
  { countryCodes: ["+262"], country: "YT" },
  { countryCodes: ["+52"], country: "MX" },
  { countryCodes: ["+691"], country: "FM" },
  { countryCodes: ["+373"], country: "MD" },
  { countryCodes: ["+377"], country: "MC" },
  { countryCodes: ["+976"], country: "MN" },
  { countryCodes: ["+382"], country: "ME" },
  { countryCodes: ["+1-664"], country: "MS" },
  { countryCodes: ["+212"], country: "MA" },
  { countryCodes: ["+258"], country: "MZ" },
  { countryCodes: ["+95"], country: "MM" },
  { countryCodes: ["+264"], country: "NA" },
  { countryCodes: ["+674"], country: "NR" },
  { countryCodes: ["+977"], country: "NP" },
  { countryCodes: ["+31"], country: "NL" },
  { countryCodes: ["+599"], country: "AN" },
  { countryCodes: ["+687"], country: "NC" },
  { countryCodes: ["+64"], country: "NZ" },
  { countryCodes: ["+505"], country: "NI" },
  { countryCodes: ["+227"], country: "NE" },
  { countryCodes: ["+234"], country: "NG" },
  { countryCodes: ["+683"], country: "NU" },
  { countryCodes: ["+850"], country: "KP" },
  { countryCodes: ["+47"], country: "NO" },
  { countryCodes: ["+968"], country: "OM" },
  { countryCodes: ["+92"], country: "PK" },
  { countryCodes: ["+680"], country: "PW" },
  { countryCodes: ["+970"], country: "PS" },
  { countryCodes: ["+507"], country: "PA" },
  { countryCodes: ["+675"], country: "PG" },
  { countryCodes: ["+595"], country: "PY" },
  { countryCodes: ["+51"], country: "PE" },
  { countryCodes: ["+63"], country: "PH" },
  { countryCodes: ["+48"], country: "PL" },
  { countryCodes: ["+351"], country: "PT" },
  { countryCodes: ["+1-787"], country: "PR" },
  { countryCodes: ["+1-939"], country: "PR" },
  { countryCodes: ["+974"], country: "QA" },
  { countryCodes: ["+40"], country: "RO" },
  { countryCodes: ["+7"], country: "RU" },
  { countryCodes: ["+250"], country: "RW" },
  { countryCodes: ["+290"], country: "SH" },
  { countryCodes: ["+1-869"], country: "KN" },
  { countryCodes: ["+1-758"], country: "LC" },
  { countryCodes: ["+508"], country: "PM" },
  { countryCodes: ["+1-784"], country: "VC" },
  { countryCodes: ["+685"], country: "WS" },
  { countryCodes: ["+378"], country: "SM" },
  { countryCodes: ["+239"], country: "ST" },
  { countryCodes: ["+966"], country: "SA" },
  { countryCodes: ["+221"], country: "SN" },
  { countryCodes: ["+381"], country: "RS" },
  { countryCodes: ["+248"], country: "SC" },
  { countryCodes: ["+232"], country: "SL" },
  { countryCodes: ["+65"], country: "SG" },
  { countryCodes: ["+421"], country: "SK" },
  { countryCodes: ["+386"], country: "SI" },
  { countryCodes: ["+677"], country: "SB" },
  { countryCodes: ["+252"], country: "SO" },
  { countryCodes: ["+27"], country: "ZA" },
  { countryCodes: ["+82"], country: "KR" },
  { countryCodes: ["+211"], country: "SS" },
  { countryCodes: ["+34"], country: "ES" },
  { countryCodes: ["+94"], country: "LK" },
  { countryCodes: ["+249"], country: "SD" },
  { countryCodes: ["+597"], country: "SR" },
  { countryCodes: ["+268"], country: "SZ" },
  { countryCodes: ["+46"], country: "SE" },
  { countryCodes: ["+41"], country: "CH" },
  { countryCodes: ["+963"], country: "SY" },
  { countryCodes: ["+886"], country: "TW" },
  { countryCodes: ["+992"], country: "TJ" },
  { countryCodes: ["+255"], country: "TZ" },
  { countryCodes: ["+66"], country: "TH" },
  { countryCodes: ["+670"], country: "TL" },
  { countryCodes: ["+228"], country: "TG" },
  { countryCodes: ["+690"], country: "TK" },
  { countryCodes: ["+676"], country: "TO" },
  { countryCodes: ["+1-868"], country: "TT" },
  { countryCodes: ["+216"], country: "TN" },
  { countryCodes: ["+90"], country: "TR" },
  { countryCodes: ["+993"], country: "TM" },
  { countryCodes: ["+1-649"], country: "TC" },
  { countryCodes: ["+688"], country: "TV" },
  { countryCodes: ["+256"], country: "UG" },
  { countryCodes: ["+380"], country: "UA" },
  { countryCodes: ["+971"], country: "AE" },
  { countryCodes: ["+44"], country: "GB" },
  { countryCodes: ["+1"], country: "US" },
  { countryCodes: ["+598"], country: "UY" },
  { countryCodes: ["+998"], country: "UZ" },
  { countryCodes: ["+678"], country: "VU" },
  { countryCodes: ["+379"], country: "VA" },
  { countryCodes: ["+58"], country: "VE" },
  { countryCodes: ["+84"], country: "VN" },
  { countryCodes: ["+681"], country: "WF" },
  { countryCodes: ["+967"], country: "YE" },
  { countryCodes: ["+260"], country: "ZM" },
  { countryCodes: ["+263"], country: "ZW" },
];
