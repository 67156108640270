import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React from "react";
import ErrorPage from "./components/fallbacks/ErrorPage";
import { TrackRequest } from "./components/TrackRequest";
import { ConfigProvider } from "antd";
import { AdminSettings } from "./components/admin/AdminSettings";
import Home from "./components/Home";
import { Category } from "./components/admin/Category";
import { Services } from "./components/admin/Services";
import { Forms } from "./components/admin/Forms";
import "react-quill/dist/quill.snow.css";
import "react-toastify/dist/ReactToastify.css";
import CredentialManagement from "./components/PortalCredentials/CredentialManagement";
import { Report } from "./components/user/Report";
import ErrorBoundary from "antd/es/alert/ErrorBoundary";
import { Layout } from "./layout/Layout";
import { ProtectedRoute } from "./routeWrapper/ProtectedRoute";
import { Optionslist } from "./components/admin/Optionslist";
import Portals from "./components/admin/portal/Portals";


function App() {

  const router = createBrowserRouter([
    
    {
      path: "user",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <ProtectedRoute
              element={
                <ErrorBoundary>
                    <Home />
                </ErrorBoundary>
              }
              allowedRoles={["customer"]}
            />
          ),
        },
        {
          path: "track-request",
          element: (
            <ProtectedRoute
              element={
                <ErrorBoundary>
                  <TrackRequest />
                </ErrorBoundary>
              }
              allowedRoles={["customer"]}
            />
          ),
        },
        {
          path: "portals",
          element: (
            <ProtectedRoute
              element={
                <ErrorBoundary>
                 <CredentialManagement />
                </ErrorBoundary>
              }
              allowedRoles={["customer"]}
            />
          ),
        },
        {
          path: "report",
          element: (
            <ProtectedRoute
              element={
                <ErrorBoundary>
                 <Report />
                </ErrorBoundary>
              }
              allowedRoles={["customer"]}
            />
          ),
        },
      ],
    },
    {
      path: "admin",
      element: <Layout />,
      children: [
        {
          path: "",
          element: (
            <ProtectedRoute
              element={
                <ErrorBoundary>
                  <Optionslist />
                </ErrorBoundary>
              }
              allowedRoles={["superAdmin", "admin"]}
            />
          ),
        },

        {
          path: "categories",
          element: (
            <ProtectedRoute
              element={
                <ErrorBoundary>
                  <Category />
                </ErrorBoundary>
              }
              allowedRoles={["superAdmin", "admin"]}
            />
          ),
        },
        {
          path: "services",
          element: (
            <ProtectedRoute
              element={
                <ErrorBoundary>
                  <Services />
                </ErrorBoundary>
              }
              allowedRoles={["superAdmin", "admin"]}
            />
          ),
        },
        {
          path: "forms",
          element: (
            <ProtectedRoute
              element={
                <ErrorBoundary>
                 <Forms />
                </ErrorBoundary>
              }
              allowedRoles={["superAdmin", "admin"]}
            />
          ),
        },
        {
          path: "settings",
          element: (
            <ProtectedRoute
              element={
                <ErrorBoundary>
                  <AdminSettings />
                </ErrorBoundary>
              }
              allowedRoles={["superAdmin", "admin"]}
            />
          ),
        }, 
        { path: "portals", element: (
          <ProtectedRoute
            element={
              <ErrorBoundary>
                <Portals />
              </ErrorBoundary>
            }
            allowedRoles={["superAdmin", "admin"]}
          />
        ), },
      ],
    },
    { path: "*", element: <ErrorPage /> },
  ]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBg: "#59C080",
            headerColor: "#fff",
          },
        },
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
