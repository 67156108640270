import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../components/common/Header";
import Footer from "../components/common/Footer";

export const Layout = () => {
  const location = useLocation();
  const isCheckPage = location.pathname.includes("/report");

  return (
    <div style={{ background: isCheckPage ? "#f6f6fb" : "white" }}>
      <Header />
      <div className="container d-flex flex-column h-100 text-center" style={{minHeight:"65vh"}}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};
