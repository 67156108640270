import { Modal, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import getCountrySVG from "react-country-svg-flag";

import { CloseOutlined } from "@ant-design/icons";

import { useMediaQuery } from "react-responsive";
import { countryList } from "../../utils/country";
import { CountryIcon } from "../../utils/Icons";

const RegionSwitcher = () => {
  const [options, setOptions] = useState([]);
  const countries = countryList;
  const [country, setCountry] = useState(sessionStorage.getItem('country'));
  const [openModal , setOpenModal]= useState(false);

  const isLargeScreen = useMediaQuery({ query: "(min-width: 1200px)" });
  const isMediumScreen = useMediaQuery({ query: "(min-width: 768px)" });
  const isSmallScreen = useMediaQuery({ query: "(max-width: 767px)" });

  let modalWidth = "650px";
  if (isLargeScreen) {
    modalWidth = "650px";
  } else if (isMediumScreen) {
    modalWidth = "650px";
  } else if (isSmallScreen) {
    modalWidth = "95%";
  }

  useEffect(() => {
    if (countries.length) {
      let option = [];
      countries.forEach((item) => {
        let flag = getCountrySVG(item.code.toLowerCase());
        option.push({
          label: (
            <div className="countryOption ff-ws">
              <span
                dangerouslySetInnerHTML={{
                  __html: flag,
                }}
                className="tw-max-w-[20px] tw-h-[20px]"
              ></span>
              {" " + item.name}
            </div>
          ),
          value: item.countryCode,
        });
      });
      setOptions(option);
    }
  }, [countries]);

  const handleChangeCountry = useCallback(
    (value) => {
      // setCountry(value);
      setOpenModal(true);
    },
    [setCountry]
  );

  const filterOption = (input, option) => {
    return (
      option.label.props.children[1]
        .toLowerCase()
        .includes(input.toLowerCase()) ||
      option.value.toString().toLowerCase().includes(input.toLowerCase())
    );
  };

  const handleLoginAgain = () =>{
    window.location.href = 'https://governify.tasc360.com';
  }

  
  return (
    <>
    <Select
      className={`text-start region-select mb-2`}
      style={{cursor:'pointer'}}
      value={country}
      placeholder="Select Country"
      onChange={(value) => handleChangeCountry(value)}
      options={options}
      dropdownStyle={{width: 'auto'}}
      placement="bottomLeft"
      filterOption={filterOption}
    />

       <Modal
        width={modalWidth}
        open={openModal}
        zIndex={9999}
        closeIcon={
          <CloseOutlined
            className="fs-18"
            style={{ color: "#202223", marginTop: "5px" }}
          />
        }
        title={
          <span className="fs-24" style={{ color: "#202223" }}>
            Change country?
          </span>
        }
        onCancel={() => setOpenModal(false)}
        footer={<div className="text-center pt-3 pb-2" style={{borderTop: '1px solid #858B932E'}}>
          <div className="d-flex justify-content-center" style={{gap: '10px'}}>
          <button className="px-3 py-2 fs-16" style={{border: 'none', borderRadius: '8px', fontWeight: '600'}} onClick={() => setOpenModal(false)}>
            Stay here!
          </button>
          <button className="px-3 py-2 fs-16" style={{border: 'none', background: '#59c080', borderRadius: '8px', fontWeight: '600', color: '#fff'}} onClick={handleLoginAgain}>
            Re-login
          </button>
          </div>
        </div>}
        centered
      >
        <div>
          <div className="text-center mt-4">
            <CountryIcon />
          </div>
          <div className="text-center fs-20 mt-2" style={{ fontWeight: 600 }}>
            Are you sure you want to change country to UAE?
          </div>
          <div
            className="text-center fs-16 mt-3"
            style={{ color: "#6D7175", maxWidth: "560px" }}
          >
            By switching regions, data from your current region will be hidden
            until you switch back. You can create and track new requests in the
            new region. You'll need to re-login after switching.
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RegionSwitcher;
