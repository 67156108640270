import { Services } from "./axios";



export const getDashboardDataApi = () => Services.GET(`governify/customer/dashboardata`);


export const getRequestTrackingDataApi = () => Services.POST(`governify/customer/requestTracking`);



export const getServiceRequestDataApi = () => Services.GET(`governify/customer/serviceRequests`);

export const getAllProfileDataApi = () => Services.GET(`newonboardify/customer/allProfileWithServicesByUser`);

export const getComplanceChartDataApi = () => Services.GET(`governify/customer/getAllComplianceReportForCustomer`);

export const getServiceChartDataApi = () => Services.GET(`governify/customer/getServiceReport`);
















