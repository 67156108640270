
import { Input } from "antd";
import React from "react";
import { MailIcon } from "../../../utils/Icons";

const EmailField = ({email, setEmail, formErrorFields, item, handleInputChange, index, emailErrorMessage}) => {
  return (
    <div className="d-flex flex-column" style={{ gap: "5px" }}>
      <div className="fs-16" style={{ color: "#202223" }}>
        {item.label} {item.required && <span className="text-danger">*</span>}
      </div>
      <Input
        value={item.value}
        placeholder="Enter the email address here"
        className="py-2 px-3 email-inp" 
        autoComplete="off"
        onChange={(e) => handleInputChange(e, index)}
        prefix={<MailIcon width={20} height={16} />}
        status={formErrorFields.includes('email') ? "error" : ""}
        style={{fontFamily: "Graphie-regular"}}
      />
      {formErrorFields.includes('email') && <span style={{ color: "#FD5749", marginTop: '5px' }} className="fs-s">Please Enter a valid Email.</span>}
    </div>
  );
};

export default EmailField;
